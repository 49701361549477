<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card title="All Students">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  class="d-inline-block mr-1"
                  placeholder="Search"
                  type="text"
                />
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :to="{name: 'add-students'}"
                  class="d-inline-flex mr-1"
                  variant="outline-primary"
                >
                  <feather-icon
                    class="mr-50"
                    icon="PlusIcon"
                  />
                  <span class="align-middle text-nowrap">Invite Student</span>
                </b-button>
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="students"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Button -->
              <span v-if="props.column.field === 'buttons'">
                <b-button
                  :to="{name: 'student-manager', params: {id: props.row.id}}"
                  size="sm"
                  variant="outline-primary"
                >Student Manager</b-button>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCard, BCol, BFormGroup, BFormInput, BFormSelect, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BButton,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    BRow,
    VueGoodTable,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'full_name',
        },
        {
          label: 'Phone',
          field: 'phone_number',
        },
        {
          label: 'Linkedin',
          field: 'linkedin_profile',
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
        },
      ],
      students: [],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  apollo: {
    students: {
      query: gql`
        query students($incubator_id: Int!) {
            users_associationtable(where: {incubator_id: {_eq: $incubator_id}, role: {_in: ["student"]}}) {
            id
            users_customuser {
              full_name
              linkedin_profile
              phone_number
            }
          }
        }`,
      variables: {
        incubator_id: getUserData()
          .associatedOrgDetails
          .filter(e => e.role === 'superadmin')[0].organization_id,
      },
      update(data) {
        const result = []
        data.users_associationtable.forEach(student => {
          result.push({
            id: student.id,
            ...student.users_customuser,
          })
        })
        return result
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
